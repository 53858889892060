import React, { createRef } from "react";
import BigTitle from "../../Components/BigTitle";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/DemoRequest.json";
import "./DemoRequest.scss";
import Meta from "../../Components/Meta";
import { MDBRow, MDBCol, MDBInput, MDBAnimation, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

class DemoRequest extends React.Component {
  state = {
    lang: this.props.match.params.lang,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    company_name: "",
    subject: "",
    message: "",
    country: "",
    region: "",
    data: {},
    meta: {
      title: dataJSON.default[this.props.match.params.lang].meta.title,
      description: dataJSON.default[this.props.match.params.lang].meta.description,
      canonical: dataJSON.default[this.props.match.params.lang].meta.canonical,
      image: dataJSON.default[this.props.match.params.lang].meta.image,
      keywords: dataJSON.default[this.props.match.params.lang].meta.keywords
    },
    bigtitle: {},
    label: {},
    validate: {},
    isVerified: false
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcRCgYhAAAAAE959V_cpYVPav7dscBaucxP8jc_";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    this.setState({
      lang: this.props.match.params.lang,
      data: dataJSON.default[this.state.lang],
      meta: dataJSON.default[this.state.lang].meta,
      bigtitle: dataJSON.default[this.state.lang].bigtitle,
      label: dataJSON.default[this.state.lang].label,
      validate: dataJSON.default[this.state.lang].validate
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lang !== this.props.match.params.lang) {
      this.setState({
        lang: this.props.match.params.lang,
        data: dataJSON.default[this.state.lang],
        meta: dataJSON.default[this.state.lang].meta,
        bigtitle: dataJSON.default[this.state.lang].bigtitle,
        label: dataJSON.default[this.state.lang].label,
        validate: dataJSON.default[this.state.lang].validate
      });
    }
  }
  submitHandler = (event) => {
    event.preventDefault();

    const form = event.target;
    const submitBtn = event.target.querySelector("[type=submit]");
    const validate = document.querySelector(".form-validate");

    form.classList.add('was-validated');

    validate.style.opacity = 0;
    validate.textContent = '';
    if (!form.checkValidity()) {
      form.querySelector(":invalid").scrollIntoView({ block: 'center', behavior: 'smooth' });
      return;
    }
    submitBtn.disabled = false;
    // console.log(this.state.message);
    fetch("https://mad-up.biz/Back-end/contactRequest", {
      method: "POST",
      body: JSON.stringify({
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phone: this.state.phone,
        request: "DEMO",
        company_name: this.state.company_name,
        country: this.state.country,
        region: this.state.region,
        subject: this.state.subject,
        message: ""
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((data) => {
      validate.scrollIntoView({ block: 'center', behavior: 'smooth' });
      if (data.status === 200) {
        console.log(data);
        validate.textContent = this.state.data.validate.success;
        validate.classList.add("submit");
        validate.style.opacity = 1;
        validate.style.animation =
          "slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
        // document.querySelector(".message").textContent = this.state.validate.valid
        form.classList.remove('was-validated');

        this.setState({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          company_name: "",
          country: "",
          region: "",
          subject: "",
          validate: {},
          isVerified: false
        });

        document.querySelector('#invalidCheck').checked = false;
      }
      if (data.status === 400 || data.status === 422) {
        console.log('error 400 ou error 422')
        validate.textContent = this.state.data.validate.error;
        validate.classList.remove("submit");
        validate.classList.add("error");
        validate.style.opacity = 1;
        validate.style.animation =
          "slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
      }
      if (data.status === 409) {
        console.log("error 409")
        validate.textContent = this.state.data.validate.error;
        validate.classList.add("submit");
        validate.style.opacity = 1;
        validate.style.animation =
          "slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
      }
    });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSubject = (value) => {
    this.setState({ subject: value });
  };

  render() {
    return (
      <>
        <Meta meta={this.state.meta} />
        <MDBAnimation type="fadeIn">
          {/* <Breadcrumb title={this.state.data.breadcrumb} /> */}

          <BigTitle
            text=""
            title={this.state.bigtitle.title}
            span={this.state.bigtitle.span}
            direct="left"
          />
          <div className="container contact">
            <form
              className="needs-validation"
              id="someForm"
              onSubmit={this.submitHandler}
              noValidate
            >
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput
                    value={this.state.lastname}
                    name="lastname"
                    onChange={this.changeHandler}
                    type="text"
                    label={this.state.label.lastname}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    value={this.state.firstname}
                    name="firstname"
                    onChange={this.changeHandler}
                    type="text"
                    label={this.state.label.firstname}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    value={this.state.company_name}
                    name="company_name"
                    onChange={this.changeHandler}
                    type="text"
                    id=""
                    label={
                      this.state.label.company_name
                    }
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.email}
                    onChange={this.changeHandler}
                    type="email"
                    name="email"
                    label={this.state.label.email}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.phone}
                    onChange={this.changeHandler}
                    type="phone"
                    name="phone"
                    label={this.state.label.phone}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.country}
                    onChange={this.changeHandler}
                    type="country"
                    name="country"
                    label={this.state.label.country}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.region}
                    onChange={this.changeHandler}
                    type="region"
                    name="region"
                    label={this.state.label.region}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <MDBDropdownToggle caret color="#757575">
                      {this.state.subject || this.state.label.placeholderSubject}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem onClick={() => this.setSubject(this.state.label.call)}>
                        {this.state.label.call}
                      </MDBDropdownItem>
                      <MDBDropdownItem onClick={() => this.setSubject(this.state.label.demo)}>
                        {this.state.label.demo}
                      </MDBDropdownItem>
                      <MDBDropdownItem onClick={() => this.setSubject(this.state.label.visio)}>
                        {this.state.label.visio}
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBCol>
              </MDBRow>
              <MDBRow><MDBCol md="12" className="mb-5"></MDBCol></MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-5">
                  <div className="custom-control custom-checkbox pl-4">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      value=""
                      id="invalidCheck"
                      required
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="invalidCheck"
                    >
                      {this.state.label.checkbox}
                    </label>
                    <div className="invalid-feedback">
                      {this.state.validate.checkbox}
                    </div>
                  </div>
                </MDBCol>
                <MDBCol md="12" className="mb-5">

                  <p className="recaptcha"></p>
                  {/* <p className="message"></p> */}
                </MDBCol>
              </MDBRow>

              <button
                type="submit"
                className="button button_submit button_blue"
              >
                <span className="hyphen"></span>
                <span className="button_text">
                  {this.state.label.button_text}
                </span>
              </button>
              <MDBRow><MDBCol md="12" className="mb-5"></MDBCol></MDBRow>
              <div className="form-validate">
              </div>
              <MDBRow><MDBCol md="12" className="mb-5"></MDBCol></MDBRow>
            </form>
          </div>
        </MDBAnimation>
      </>
    );
  }
}

//   render() {
//     const messageMail = this.state.messageReponse;
//     const messageError = this.state.messageError;


//     if (messageMail) {
//       document.querySelector(".message").innerHTML =
//         this.state.data.validate.reponse;
//       document.querySelector(".message").classList.add("reponseTrue");
//     }

//     if (messageError) {
//       document.querySelector(".message").innerHTML =
//         this.state.data.validate.error;
//       document.querySelector(".message").classList.add("reponseError");
//     }

//     const InputEmail = Yup.object().shape({
//       email: Yup.string()
//         .email(this.state.validate.errorMail)
//         .required(this.state.validate.errorForm),
//       firstname: Yup.string().required(this.state.validate.errorForm),
//       lastname: Yup.string().required(this.state.validate.errorForm),
//       phone: Yup.string().required(this.state.validate.errorForm),
//       // recaptcha: Yup.string().required(this.state.validate.errorForm),
//       // region: Yup.string().required(this.state.validate.errorForm),
//       country: Yup.string().required(this.state.validate.errorForm),
//       subject: Yup.string().required(this.state.validate.errorForm),
//       company_name: Yup.string().required(this.state.validate.errorForm),
//     });

//     this.state.country.sort(function compare(a, b) {
//       if (a.translations.fra.common < b.translations.fra.common)
//         return -1;
//       if (a.translations.fra.common > b.translations.fra.common)
//         return 1;
//       return 0;
//     });

//     this.state.country.sort(function compare(a, b) {
//       if (a.name.common < b.name.common)
//         return -1;
//       if (a.name.common > b.name.common)
//         return 1;
//       return 0;
//     });
//     this.state.regionFr.sort(function compare(a, b) {
//       if (a.nom < b.nom)
//         return -1;
//       if (a.nom > b.nom)
//         return 1;
//       return 0;
//     });
//     return (
//       <>
//         <Meta meta={this.state.meta} />
//         <BigTitle
//           text=""
//           title={this.state.bigtitle.title}
//           span={this.state.bigtitle.span}
//           direct="left"
//         />

//         <div className="form ">
//           <Formik
//             initialValues={{
//               firstname: "",
//               lastname: "",
//               email: "",
//               phone: "",
//               company_name: "",
//               country: "",
//               region: "",
//               subject: "",
//               recaptcha: "",
//             }}
//             onSubmit={(values) => {
//               this.handleSubmit(values);
//               // console.log(values)
//             }}
//             validationSchema={InputEmail}
//             render={({
//               values,
//               errors,
//               touched,
//               handleSubmit,
//               setFieldValue,
//               handleBlur,
//               handleChange,
//               isSubmitting,
//             }) => (
//               <form className="checkboxMail" onSubmit={handleSubmit} >
//                 <div className="formLabel">
//                   <div className="form-group mb-3">
//                     <label htmlFor="name">{this.state.label.firstName}</label>
//                     <Field
//                       type="text"
//                       id="firstname"
//                       name="firstname"
//                       className="form-control input"
//                     />
//                     <ErrorMessage
//                       name="firstname"
//                       component="small"
//                       className="text-danger"
//                     />
//                   </div>

//                   <div className="form-group mb-3">
//                     <label htmlFor="lastname">
//                       {this.state.label.lastName}
//                     </label>
//                     <Field
//                       type="text"
//                       id="lastname"
//                       name="lastname"
//                       className="form-control input"
//                     />
//                     <ErrorMessage
//                       name="lastname"
//                       component="small"
//                       className="text-danger"
//                     />
//                   </div>
//                 </div>

//                 <div className="form-group mb-3">
//                   <label htmlFor="company_name">{this.state.label.company_name}</label>
//                   <Field
//                     type="text"
//                     id="company_name"
//                     name="company_name"
//                     className="form-control input"
//                   />
//                   <ErrorMessage
//                     name="company_name"
//                     component="small"
//                     className="text-danger"
//                   />
//                 </div>
//                 <div className="formLabel">
//                   <div className="form-group  mb-3">
//                     <label htmlFor="email">{this.state.label.email}</label>

//                     <Field
//                       type="email"
//                       id="email"
//                       name="email"
//                       className="form-control input"
//                     />
//                     <ErrorMessage
//                       name="email"
//                       component="small"
//                       className="text-danger"
//                     />
//                   </div>
//                   <div className="form-group mb-3">
//                     <label htmlFor="phone">{this.state.label.phone}</label>
//                     <Field
//                       type="text"
//                       id="phone"
//                       name="phone"
//                       className="form-control input"
//                     />
//                     <ErrorMessage
//                       name="phone"
//                       component="small"
//                       className="text-danger"
//                     />

//                   </div>
//                 </div>

//                 <div className="form-group mb-3">
//                   <label htmlFor="country">{this.state.label.country}</label>
//                   <Field as="select" name="country" className="form-control input">
//                     <option disabled value="">{this.state.label.placeholderCountry}</option>
//                     {this.state.country.map((country, index) => {
//                       return (this.state.lang === "fr") ?
//                         <option key={index} value={country.translations.fra.common}>
//                           {country.translations.fra.common}
//                         </option>
//                         :
//                         <option key={country.name.common} value={country.name.common}>
//                           {country.name.common}
//                         </option>
//                     })}
//                   </Field>
//                   <ErrorMessage
//                     name="country"
//                     component="small"
//                     className="text-danger"
//                   />


//                 </div>
//                 {values.country === "France" &&
//                   <div className="form-group mb-3">
//                     <label htmlFor="region">{this.state.label.region}</label>

//                     <Field as="select" name="region" className="form-control input" >
//                       <option disabled value="">{this.state.label.placeholderRegion}</option>
//                       {this.state.regionFr.map(({ nom, index }) => (
//                         <option key={nom} value={nom}>
//                           {nom}
//                         </option>
//                       ))}
//                     </Field>
//                     <ErrorMessage
//                       name="region"
//                       component="small"
//                       className="text-danger"
//                     />
//                   </div>
//                 }

//                 {((values.country === "Spain") || (values.country === "Espagne")) &&
//                   <div className="form-group mb-3">
//                     <label htmlFor="region">{this.state.label.region}</label>

//                     <Field as="select" name="region" className="form-control input"  >
//                       <option disabled value="">{this.state.label.placeholderRegion}</option>
//                       {this.state.commune.sort().map((nom) => (
//                         <option key={nom} value={nom}>
//                           {nom}
//                         </option>
//                       ))}
//                     </Field>
//                     <ErrorMessage
//                       name="region"
//                       component="small"
//                       className="text-danger"
//                     />
//                   </div>
//                 }

//                 <div className="form-group mb-3">
//                   <label htmlFor="subject">{this.state.label.subject}</label>
//                   <Field as="select" name="subject" className="form-control input">
//                     <option disabled value="">{this.state.label.placeholderSubject}</option>
//                     {this.state.subject.sort().map((subject, index) => (

//                       <option key={index} value={subject}>
//                         {subject}
//                       </option>

//                     ))}
//                   </Field>
//                   <ErrorMessage
//                     name="subject"
//                     component="small"
//                     className="text-danger"
//                   />
//                 </div>


//                 <div className="form-group">
//                   <Recaptcha
//                     sitekey="6LcRCgYhAAAAAFZQ0kUrOCx-HgCMVxhlWNZ2gZMr"
//                     render="explicit"
//                     verifyCallback={(response) => {
//                       console.log(response);
//                       setFieldValue("recaptcha", response);
//                     }}
//                     onloadCallback={() => {
//                       console.log("done loading!");
//                     }}
//                   />
//                   {errors.recaptcha && touched.recaptcha && (
//                     <p className="text-danger small">{errors.recaptcha}</p>
//                   )}
//                 </div>

//                 <button
//                   type="submit"
//                   className="colorButton"
//                   disabled={isSubmitting}
//                 >
//                   {this.state.validate.textSubmit}
//                 </button>
//               </form>
//             )}
//           />

//           <p className="message"></p>
//         </div>
//       </>
//     );
//   }
// }

export default withRouter(DemoRequest);
